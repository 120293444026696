import React, { useState, useEffect } from "react";

const artworksData = [
  {
    id: 1,
    imageUrl: "img/recognition1.webp",
  },
  {
    id: 2,
    imageUrl: "img/recognition2.webp",
  },
];

function Decieve() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % artworksData.length);
  };

  return (
    <div className="slideshow-container">
      <br />
      <div className="slideshow">
        {artworksData.map((artwork, index) => (
          <div
            key={artwork.id}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <img
              src={artwork.imageUrl}
              alt="Artwork"
              className="artworksimg-technoandon"
            />
          </div>
        ))}
      </div>
      <h2 className="artworkstitle">Recognition Capture Scene</h2>
      <br />
      <br />
      <div className="artworkscontainer">
        <p className="artworksdetail">
          パノラマ写真は、1840年代に考案されたスイングレンズ方式のカメラを起源を持ち、スマートフォンの登場により身近なものとなりました。
          通常のパノラマ撮影では、カメラが捉えた連続した映像がつなぎ合わされて一枚の長細い画像が生成されます。しかし本作品では、iPhoneの前に設置されているディスプレイに表示されている映像は右から左に流れていくだけですが，iPhoneを通して見るとパノラマ撮影が行われており，ディスプレイに表示されているものとiPhoneでパノラマ撮影されたあとの表示結果が鑑賞者が実際に見ている光景とパノラマ撮影された像に"ずれ"を生じさせます．
          使用しているiPhoneには一切技術的に特別なことはしておらず，鑑賞者も自身のiPhoneで体験していただくことが可能です．
          普段何気なく使っている機能を違う使い方をすることで、この作品の体験を通して鑑賞者に提示し、新しい視覚体験や見方を促す試みです。
        </p>
        <br />
        <hr></hr>
        <br />
        <p className="artworksdetailen">
          Panoramic photography has its origins in the swing-lens camera
          invented in the 1840s and has become familiar with the advent of
          smartphones. In ordinary panoramic photography, a series of images
          captured by a camera are stitched together to form a single long,
          narrow image. In this work, however, the image on the display in front
          of the iPhone simply flows from right to left, but when viewed through
          the iPhone, a panoramic shot is taken, and the result of what is shown
          on the display and what is displayed after the panoramic shot was
          taken with the iPhone is the same as the viewer's The result of the
          display on the display and the result of the panorama shot by the
          iPhone cause a “gap” between what the viewer is actually seeing and
          the panorama image. No special technical modifications are made to the
          iPhone, and the viewer can experience this with his/her own iPhone.
          This is an attempt to present the viewer with a different way of using
          a function that he/she normally uses without thinking about it, and to
          encourage a new visual experience and way of seeing through the
          experience of this work. 
        </p>
        <br />
        <br />
        <iframe width="350px" height="500" src="https://www.youtube.com/embed/jZeSh5alXvM?si=o8OYX7RXchYblfUO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-med
        ia; gyroscope; picture-in-picture; web-share" allowfullscreen className="responsive-iframe">
        </iframe>
        <p className="credit">
          Credit:
          <br />
          <a href="/">Rintaro Fujita(Creator)</a>
          <br />
          <a href="https://scottallen.ws/biography">Scott Allen(Advisor)</a>
          <br />
        </p>
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Decieve;
