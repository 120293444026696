import React from 'react';

function Loading() {
  return (
    <div className="loading-spinner">
    </div>
  );
}

export default Loading;
