import React, { useState, useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom'; // Fixed the import

import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import Loading from './components/Loading';
import Decieve from './pages/decieve';
import PulsatioLitoris from './pages/ebbs_and_flows';
import Hackognition from './pages/hackognition';
import portfolio from './pages/portfolio';
import tongueprotecter from './pages/tongue_protecter';
import technosomen from './pages/technosomen';
import bitshape from './pages/bitshape';
import technoandon from './pages/technoandon';
import recognitoncapturescrne from './pages/reognition_capture_scene.js';
import '../src/App.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadingStatus = localStorage.getItem('loadingStatus');
    if (loadingStatus === 'loaded') {
      setIsLoading(false);
    } else {
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
        localStorage.setItem('loadingStatus', 'loaded');
      }, 1000);

      return () => {
        clearTimeout(loadingTimeout);
      };
    }
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <HashRouter>
          <Header />
          <Switch>
            <Route exact path="/" component={Main} />
            <Route path="/decieve" component={Decieve} />
            <Route path="/pulsatiolitoris" component={PulsatioLitoris} />
            <Route path="/hackognition" component={Hackognition} />
            <Route path="/portfolio" component={portfolio} />
            <Route path="/tongueprotecter" component={tongueprotecter} />
            <Route path="/technosomen" component={technosomen} />
            <Route path="/bitshape" component={bitshape} />
            <Route path="/technoandon" component={technoandon} />
            <Route path="/recognitioncapturescene" component={recognitoncapturescrne} />
          </Switch>
          <Footer />
        </HashRouter>
      )}
    </div>
  );
}

export default App;
